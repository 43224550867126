import { BsFillBarChartLineFill, BsFillGearFill, BsBootstrapReboot, BsQuestionSquare } from 'react-icons/bs';
import { Row, Col, Container } from 'react-bootstrap';


export const Header = ({ setShowResetModal, setShowModal, resetGameAndStorage, setSettings, resetModal, setResetModal, setHowToPlay }) => {
  

  const handleStats = () => {
    setShowModal(true);
  };

  const handleSettings = () => {
    setSettings(true)
  }

  const handleReset = () => {
    setShowResetModal(true)
  }

  const handleHowTo = () => {
    setHowToPlay(true)
  }
  return (
    <div>
    <Container className='mt-3'>
      <Row>
        <Col>
          <BsQuestionSquare size={45} onClick={handleHowTo}/>
          <p>How to play</p>
        </Col>
        <Col className=''>
          <BsBootstrapReboot onClick={handleReset} size={45}> Reset game and stats</BsBootstrapReboot>
          <p>Reset stats</p>
        </Col>
        <Col className=''>
          <BsFillBarChartLineFill size={45} onClick={handleStats} />
          <p>Show stats</p>
        </Col>
        <Col className=''>
          <BsFillGearFill size={45} onClick={handleSettings}/>
          <p>Settings</p>
        </Col>
      </Row>
    </Container>
     
    </div>
  );
};
