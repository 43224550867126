import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './modal.css';

export const HowToPlay = ({ setHowToPlay, howToPlay }) => {

  const handleClose = () => setHowToPlay(false);
  const handleShow = () => setHowToPlay(true);

  return (
    <>
      <Modal show={howToPlay} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>How to play</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Guess the 5 letter word in 5 tries</p>
          <p>Each guess must be a valid word. Press Return ↵ to check the word</p>
          <p>After you presss return, the colours will change to reflect how far/close you are from the correct word</p>
          <p>Example: MARCH</p>
          <div className='rowLetter'>
            <div className="letterSmall corr">M</div>
            <div className="letterSmall wrng">O</div>
            <div className="letterSmall wrng">V</div>
            <div className="letterSmall wrng">I</div>
            <div className="letterSmall wrng">E</div>
            
          </div>
          <p>Letter M is in the correct poisiton</p>

          <div className='rowLetter'>
            <div className="letterSmall wrng">B</div>
            <div className="letterSmall wrngPos">R</div>
            <div className="letterSmall wrngPos">A</div>
            <div className="letterSmall wrng">S</div>
            <div className="letterSmall wrng">S</div>

          </div>
          <p>Letter R and A are correct but in the wrong position</p>
          <div className='rowLetter'>
            <div className="letterSmall wrng">L</div>
            <div className="letterSmall wrng">O</div>
            <div className="letterSmall wrng">U</div>
            <div className="letterSmall wrng">I</div>
            <div className="letterSmall wrng">E</div>

          </div>
          <p>No correct letters</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
